<template>
  <div class="notice-details-box top-padding">
    <top-home :title="title"></top-home>
    <div
      class="details-box"
      v-if="$route.query.type=='xtgg'"
    >
      <div class="notice-title">{{detailsData.ggbt}}</div>
      <div class="notice-time">时间：{{detailsData.create_time}}</div>
      <div class="notice-content">
        <div
          class="text-rich-box info-content"
          v-if="detailsData.nr"
        >
          <div v-html="detailsData.nr"></div>
        </div>
      </div>
    </div>
    <div
      class="details-box"
      v-else
    >
      <div class="notice-time">{{detailsData.xxlx}} | {{detailsData.fsr}} | 时间：{{detailsData.fssj}}</div>
      <div class="notice-content">
        <div
          class="text-rich-box info-content"
          v-if="detailsData.xxnr"
        >
          <div v-html="detailsData.xxnr"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnnouncDetailsApi, getMessageDetailsApi } from '@/reuqest/api.js'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      detailsData: {},
    }
  },
  computed: {
    ...mapGetters(['tenantId']),
    title () {
      let type = this.$route.query.type
      return type == 'xtgg' ? '公告详情' : '推送消息'
    }
  },
  created () {
    const loadingToast = this.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    let type = this.$route.query.type
    if (type == 'xtgg') {
      getAnnouncDetailsApi({ noticeId: this.$route.query.id }).then((res) => {
        this.detailsData = res.data
        loadingToast.clear()
      })
    } else if (type == 'tsxx') {
      getMessageDetailsApi({ id: this.$route.query.id }).then((res) => {
        this.detailsData = res.data
        loadingToast.clear()
      })
    }
  },
}
</script>

<style lang="less" scoped>
.notice-details-box {
  padding: 59px 20px 20px;
  color: #232323;
  .notice-title {
    text-align: left;
    font-size: 18px;
  }
  .notice-time {
    text-align: left;

    font-size: 14px;
    color: #999;
    padding: 5px 0;
    border-bottom: 1px solid #f1f1f1;
  }
  .notice-content {
    font-size: 16px;
    border: 1px solid #21a4fe;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    text-indent: 2em;
    .title {
      background-color: #21a4fe;
      font-size: 18px;
      font-weight: bold;
      width: 100px;
      text-align: center;
      padding: 6px 0;
      color: #fff;
      border-radius: 0 0 5px 5px;
      margin: 0 auto;
    }
    .tip {
      font-size: 18px;
      padding: 15px 0;
      flex-wrap: 400;
    }
    .text-jj {
      padding-left: 30px;
      line-height: 25px;
      padding-bottom: 10px;
    }
    .text {
      padding-bottom: 20px;
      line-height: 25px;
    }
  }
}

/deep/.info-content p {
  margin: 0 !important;
}
</style>